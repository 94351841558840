class CgPreloader {
    constructor() {
        // Selecting elements and setting initial properties
        this.preloaderElement = document.getElementById('preloader');
        this.rootElement = document.querySelector(':root');
        this.animationInterval = 50; // Animation speed in milliseconds
    }

    // Method to start the preloader animation
    preloaderStart(successCallback) {
        let progress = 0; // Progress variable to track animation progress

        // Set up an interval for the animation
        const animationIntervalId = setInterval(() => {
            progress = progress + 30; // Increment progress
            if (document.readyState === 'complete') {
                progress = 100;
            }

            // Calculate width based on progress
            if(progress <= 101 ){
                this.rootElement.style.setProperty('--cgPreloaderValue', progress + '%');
            }

            // If progress reaches 100%
            if (progress >= 100 || document.readyState === 'complete') { // Change condition to check for 100%
                // Hide the preloader
                if(this.preloaderElement && typeof this.preloaderElement != 'undefined' ){
                    this.preloaderElement.classList.add('hide');
                }

                clearInterval(animationIntervalId); // Clear the animation interval
                progress = 0; // Reset progress
                // If a success callback is provided, call it
                if (successCallback) {
                    successCallback();
                }
            }
        }, this.animationInterval);
    }
}
// Event listener for when the DOM is fully loaded
document.addEventListener('DOMContentLoaded', () => {
    const preloader = new CgPreloader(); // Create a new instance of CgPreloader
    preloader.preloaderStart(); // Initialize the preloader when the DOM is loaded
});
